import React from 'react';
import './style.css';
import ReadMore from '../ReadMore/ReadMore';
import { Link } from 'react-router-dom';
import primaryApplication from '../../Documents/English/Renew_BMSPAF-Application.pdf';
import OncologyHematologyRenewalApplication from '../../Documents/English/Renew_OncologyHematologyRenewalApplication.pdf';
import zeposiaApplication from '../../Documents/English/Renew_ZeposiaApplication.pdf';
import RemsRenewalApplication from '../../Documents/English/Renew_REMS-RenewalApplication.pdf';
import CamzyosRenewalApplication from '../../Documents/English/Renew_BMSPAF-Camzyos-Application.pdf';
import ContactUs from '../EligibilityRequirements/ContactUs';

import OncologyHematologyRenewalApplication_Spanish from '../../Documents/Spanish/Renew_OncologyHematologyRenewalApplication.pdf';
import primaryApplication_Spanish from '../../Documents/Spanish/Renew_BMSPAF-Application.pdf';
import zeposiaApplication_Spanish from '../../Documents/Spanish/Renew_ZeposiaApplication.pdf';
import RemsRenewalApplication_Spanish from '../../Documents/Spanish/Renew_REMS-RenewalApplication.pdf';
import CamzyosRenewalApplication_Spanish from '../../Documents/Spanish/Renew_BMSPAF-Camzyos-Application.pdf';

function Renew({ pageData, lang }) {
   const backToTop = (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
   };

   const openRenewDocument = (documentTitle) => {
      let url = ' ';
      if (documentTitle === 'OncologyHematologyDocument') {
         url =
            lang === 'english'
               ? window.open(OncologyHematologyRenewalApplication)
               : window.open(OncologyHematologyRenewalApplication_Spanish);
      } else if (documentTitle === 'RemsDocument') {
         url =
            lang === 'english'
               ? window.open(RemsRenewalApplication, '_blank')
               : window.open(RemsRenewalApplication_Spanish, '_blank');
      } else if (documentTitle === 'PrimaryDocument') {
         url =
            lang === 'english'
               ? window.open(primaryApplication, '_blank')
               : window.open(primaryApplication_Spanish, '_blank');
      } else if (documentTitle === 'ZeposiaDocument') {
         url =
            lang === 'english'
               ? window.open(zeposiaApplication, '_blank')
               : window.open(zeposiaApplication_Spanish, '_blank');
      } else {
         url =
            lang === 'english'
               ? window.open(CamzyosRenewalApplication, '_blank')
               : window.open(CamzyosRenewalApplication_Spanish, '_blank');
      }

      // documentTitle === 'OncologyHematologyDocument' &&
      //    window.open(OncologyHematologyRenewalApplication, '_blank');
      // documentTitle === 'RemsDocument' &&
      //    window.open(RemsRenewalApplication, '_blank');
      // documentTitle === 'PrimaryDocument' &&
      //    window.open(primaryApplication, '_blank');
      // documentTitle === 'ZeposiaDocument' &&
      //    window.open(zeposiaApplication, '_blank');
      // documentTitle === 'CamzyosDocument' &&
      //    window.open(CamzyosRenewalApplication, '_blank');
   };

   const openHemOncDocument = () => { };

   const openRemsRenewelDocument = () => { };

   const openPrimeRenewalDocument = () => { };

   const openZeposiaAppDocument = () => { };

   const downloadDocument = () => {
      openRenewDocument();
   };
   return (
      <main id="main">
         <div className="how-to-apply">
            <div className="container-fluid">
               <div className="row g-0">
                  <div className="howto-header col-md-12 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
                     <div className="overlay"></div>
                     <div className="banner-text-div mx-auto">
                        <h1 className="banner-text">{pageData.banner.title}</h1>
                     </div>
                  </div>
               </div>
            </div>
            <div className="apply container">
               <div className="apply-title row  g-0">
                  <div className="apply-pointer-one col-md-8 col-sm-12 mx-auto">
                     <p className="title-string">{pageData.renewContent}</p>
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.assistanceList.title}</u>
                        </span>
                     </p>
                     <ul className="custom-list">
                        {pageData.assistanceList.list.map((item) => (
                           <li dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                     </ul>
                     <br />
                     <br />
                     <br />
                     <div class="container">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 {pageData.table1.headers.map((item) => (
                                    <th>
                                       <span class="bold-span">{item}</span>
                                    </th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {pageData.table1.rows.map((cell, index) => (
                                 <tr>
                                    <td>
                                       <ul>
                                          {cell[0].map((item) => (
                                             <li>{item}</li>
                                          ))}
                                       </ul>
                                    </td>
                                    <td>
                                       <span className="underline body-bold">
                                          <Link
                                             to="#"
                                             onClick={() =>
                                                openRenewDocument(cell[1])
                                             }>
                                             {cell[2]}
                                          </Link>
                                       </span>
                                       {/* {(index === 0 || index === 1) ?
                                          <span
                                             className="table-msg"
                                             dangerouslySetInnerHTML={{
                                                __html: cell[2],
                                             }}>
                                          </span>
                                          :
                                          <span className="underline body-bold">
                                             <Link
                                                to="#"
                                                onClick={() =>
                                                   openRenewDocument(cell[1])
                                                }>
                                                {cell[2]}
                                             </Link>
                                          </span>
                                       } */}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                     <hr aria-hidden="true" />
                     <br />
                     <br />
                     <br />
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.table2.title}</u>
                        </span>
                     </p>
                     <div class="container">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 {pageData.table2.headers.map((item) => (
                                    <th>
                                       <span class="bold-span">{item}</span>
                                    </th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {pageData.table2.rows.map((cell) => (
                                 <tr>
                                    <td>
                                       <span class="bold-span">{cell[0]}</span>
                                    </td>
                                    <td>{cell[1]}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                     <hr aria-hidden="true" />
                     <br />
                     <br />
                     <br />
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.impInfoList.title}</u>
                        </span>
                     </p>
                     <ul className="custom-list">
                        {pageData.impInfoList.list.map((item) => (
                           <li
                              className="custom-list-item"
                              dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                     </ul>
                     <br />
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.renewQuestion.question}</u>
                        </span>
                     </p>
                     <p className="title-string">
                        <span class="bold-span">{pageData.renewQuestion.answerList.header}</span>
                     </p>
                     <ul className="custom-list">
                        {pageData.renewQuestion.answerList.list.map((item) => (
                           <li dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                     </ul>
                     <br />
                     <p>{pageData.renewQuestion.answerList.footer}</p>
                     <br />
                  </div>
               </div>
               <div className="apply-contact row g-0">
                  <ContactUs contactData={pageData.moreInfo.contactContent} />
               </div>
               <div className="row g-0">
                  <div className="col-md-8 col-sm-12 mx-auto">
                     <Link
                        tabIndex={0}
                        style={{
                           marginTop: '1em',
                           color: 'black',
                           borderBottom: '2px solid #EB7100',
                           textDecoration: 'none',
                        }}
                        onClick={(e) => backToTop(e)}>
                        <span class="bold-span">{pageData.backBtnLabel}</span>
                     </Link>
                  </div>
               </div>
            </div>
            <ReadMore content={pageData.readMore} />
         </div>
      </main>
   );
}

export default Renew;
